body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

 body .br-0 {
  border-radius: 0;
}

p{
  font-size: 16px;
}

.icon-btn{
  padding: 5px;
  border-radius: 4px;
  border: 0px;
  background-color: transparent;
  width: max-content;
  height: max-content;
}

.todo-item{
  border-top: 1px solid #eee;
  padding: 10px 0;
}

.todo-item:last-child{
  border-bottom: 1px solid #eee;
}

.update-modal{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1500;
  position: fixed;
  padding: 10px 20px 40px 20px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}